import { computed } from "vue";
import { useStore } from "@/store"

export const useRoles = () => {
  const store = useStore();

  return {
    isManager: computed(() => store.state.role === 2),
    isAdmin: computed(() => store.state.role === 1),
    isSuperAdmin: computed(() => store.state.role === 0),
  }
};
