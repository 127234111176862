import { ref, computed } from "vue";
import { cockpitExplorerService } from "@/api";

const DEFAULT_RANGE_MS = 25 * 60 * 60 * 1000;

export const useExplorersStatistics = ({ rangeInMs = DEFAULT_RANGE_MS } = {}) => {
  const isProjectStatistics = ref(true);
  const isOthersStatistics = ref(true);

  const rangeMs = ref(rangeInMs);
  const toMs = ref(0)
  const fromMs = computed(() => toMs.value - rangeMs.value);

  const statisticsContextReqParam = computed(() => {
    const p = [];
    if (isProjectStatistics.value) {
      p.push("project");
    }
    if (isOthersStatistics.value) {
      p.push("other");
    }
    return p.join(",");
  });

  const getExplorerTestsStatistic = (id) => {
    toMs.value = Date.now();
    const to = Math.trunc(toMs.value / 1000);
    const from = Math.trunc(fromMs.value / 1000);
    const context = statisticsContextReqParam.value;
    return cockpitExplorerService.v1.getExplorerTestsStatistics({ id, to, from, context });
  };

  return {
    isProjectStatistics,
    isOthersStatistics,
    rangeMs,
    fromMs,
    toMs,
    statisticsContextReqParam,
    getExplorerTestsStatistic,
  }
};
