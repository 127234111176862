import { explorerStatusService } from "@/api"
import { computed, ref } from "vue";
import { useStore } from "@/store";
import { useNotifications } from "@/composition/notifications";

export const formatExplorerNameWithAlias = (expl) => {
  let v = expl.name;
  if (expl.alias) {
    v += ` (${expl.alias})`;
  }
  return v;
};


export const useExplorers = () => {
  const store = useStore();
  const { showNotification } = useNotifications();

  const useExplorerAlias = computed(() => {
    return store.getters["orgConfig"].useExplorerAlias;
  });

  const checkDeactivatedExplorer = (expl) => !expl.active

  const isTogglingExplorer = ref(false);
  const toggleExplorer = async (expl) => {
    isTogglingExplorer.value = true;
    try {
      await explorerStatusService.v1.toggleExplorer({ id: expl.id, isActive: !expl.active });
      showNotification({ message: `Successfully ${!expl.active ? 'activated' : 'deactivated'} ${expl.name}.` })
    } catch (err) {
      showNotification({ message: `Failed ${!expl.active ? 'activation' : 'deactivation'} ${expl.name}.` })
      throw err;
    } finally {
      isTogglingExplorer.value = false;
    }
  };

  const isRebootingExplorer = ref(false);
  const rebootExplorer = async (expl) => {
    isRebootingExplorer.value = true;

    try {
      await explorerStatusService.v1.rebootExplorer(expl.name)
      showNotification({ message: `Successfully rebooted ${expl.name}.` })
    } catch (err) {
      showNotification({ message: `Could not reboot ${expl.name}.` })
      throw err;
    } finally {
      isRebootingExplorer.value = false;
    }
  };

  const isDeletingExplorer = ref(false);
  const deleteExplorer = async (expl) => {
    isDeletingExplorer.value = true;
    try {
      await explorerStatusService.v1.deleteExplorer(expl.id);
      showNotification({ message: `Successfully deleted ${expl.name}.` })
    } catch (err) {
      showNotification({ message: `Could not delete ${expl.name}.` })
      throw err;
    } finally {
      isDeletingExplorer.value = false;
    }
  };


  return {
    useExplorerAlias,
    formatExplorerNameWithAlias,
    checkDeactivatedExplorer,
    toggleExplorer,
    isTogglingExplorer,
    rebootExplorer,
    isRebootingExplorer,
    deleteExplorer,
    isDeletingExplorer,
  };
};
