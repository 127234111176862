import { ref } from "vue";
import { cockpitExplorerService } from "@/api";
import { convertToCsv } from "@/js/helper";
import { unixToDateTimeString, dateToIsoDateString, downloadStringAsFile } from '@/js/general';
import { formatExplorerNameWithAlias } from "./use-explorers"

export const BUSINESS_STATUS_LIST = [
 {
   name: "All",
   value: -1,
 },
 {
   name: "In Operation",
   value: 4,
 },
 {
   name: "Operation Disrupted", 
   value: 9,
 },
 {
   name: "Installed",
   value: 1,
 },
 {
   name: "In Stock",
   value: 2,
 },
 {
   name: "Ready to Ship",
   value: 17,
 },
 {
   name: "Shipping",
   value: 3,
 },
 {
   name: "Delivered",
   value: 18,
 },
 {
   name: "Activation Delayed",
   value: 10,
 },
 {
   name: "Observation",
   value: 7,
 },
 {
   name: "Observation Disrupted",
   value: 8,
 },
 {
   name: "Not Connected",
   value: 11,
 },
 {
   name: "None",
   value: 0,
 },
 {
   name: "Warning",
   value: 5,
 },
 {
   name: "Error",
   value: 6,
 },
 {
   name: "Return",
   value: 12,
 },
 {
   name: "Wait for Return",
   value: 20,
 },
 {
   name: "Return Shipping",
   value: 19,
 },
 {
   name: "Return Received",
   value: 13,
 },
 {
   name: "Return Delayed",
   value: 14,
 },
 {
   name: "Inspection Delayed",
   value: 22,
 },
 {
   name: "Disposed",
   value: 15,
 },
 {
   name: "Delete",
   value: 16,
 },
 {
   name: "Pool",
   value: 21,
 }
];

const formatStatus = (value) => {
  const status = BUSINESS_STATUS_LIST.find(x => x.name.replace(" ", "") === value.current);
  if (status) return status.name;
  console.error(`Could not find status ${value.current}`);
  return value.current;
};

const formatOperationalState = (value) => {
  const states = {
    0: "Offline",
    1: "Online",
    2: "Warning"
  };
  return states[value.online] || "Unknown";
};

export const CSV_HEADERS = [
  { name: "Name", key: "name", formatter: (_, expl) => formatExplorerNameWithAlias(expl) },
  { name: "Service Code", key: "serviceCode" },
  { name: "Update Time", key: "status", formatter: value => unixToDateTimeString(value.updateTime) },
  { name: "Event Time", key: "status", formatter: value => unixToDateTimeString(value.eventTime) },
  { name: "MAC address", key: "systemInfo.systemType.networkInterfaces.eth0" },
  { name: "Status", key: "status", formatter: formatStatus },
  { name: "Description", key: "description" },
  { name: "Tags", key: "tags", formatter: value => `"${value.join(", ")}"` },
  { name: "Device Pool", key: "devicePool.name" },
  { name: "Operational State", key: "status", formatter: formatOperationalState },
  { name: "Operational State Since", key: "status", formatter: value => unixToDateTimeString(value.uptimeUpdateTime) }
];

export function useExplorerCsv({ title = "" } = {}) {
  const isCsvLoading = ref(false);

  const downloadExplorersCsv = async (_params) => {
    const p = {
      ..._params,
      full: true,
    };

    isCsvLoading.value = true;

    try {
      const result = await cockpitExplorerService.v1.getExplorersTable(p);
      const formattedDate = dateToIsoDateString(new Date());
      const csv = convertToCsv(result.records, CSV_HEADERS);

      downloadStringAsFile(
        `${title.length ? `${title} ` : title}Export ${formattedDate}.csv`,
        csv,
      );
    } finally {
      isCsvLoading.value = false;
    }
  }

  return {
    downloadExplorersCsv,
    isCsvLoading,
  };
}
